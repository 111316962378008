




























































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
    CompanyLocation,
    CompanySkill,
    CompanyContactPerson,
    CompanyContactInformation,
    CompanyServiceModal,
    CompanyPartner,
    CompanyRecommendation,
} from "@/types/company";
import { ExpansionPanelItem } from "@/types/components/expansionpanel";
import { CarouselItem } from "@/types/components/carousel";
import Carousel from "@/components/Carousel.vue";
import ServiceCard from "@/components/company/services/ServiceCard.vue";
import ServiceModal from "@/components/company/services/ServiceModal.vue";
import CountCard from "@/components/CountCard.vue";
import TeamCard from "@/components/company/team/TeamCard.vue";
import ContactCard from "@/components/company/contact/ContactCard.vue";
import ContactForm from "@/components/company/contact/ContactForm.vue";
import LocationList from "@/components/company/location/CompanyLocationList.vue";
/*import LocationMap from "@/components/company/location/CompanyLocationMap.vue";*/
import PartnerList from "@/components/company/partners/PartnerList.vue";
import RecommendationList from "@/components/company/recommendations/RecommendationList.vue";
/*import CalendlySheet from "@/components/calendly/CalendlySheet.vue";*/
import Marquee from "@/components/Marquee.vue";

@Component({
    components: {
        Carousel,
        ServiceCard,
        ServiceModal,
        CountCard,
        TeamCard,
        ContactCard,
        ContactForm,
        LocationList,
        /*LocationMap,*/
        PartnerList,
        /*CalendlySheet,*/
        RecommendationList,
        Marquee,
    },
})
export default class Home extends Vue {
    private mounted() {
        if (!this.$route.hash) return;

        const el = document.querySelector(this.$route.hash);
        if (!el) return;
        el.scrollIntoView();
    }

    private carouselItems: CarouselItem[] = [
        {
            imageSrc: require("@/assets/img/carousel/HP_Slider_ohneNeuJahr5.png"),
            heading: null,
            subheading: null,
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-meschede.png"),
            heading: "Ihr IT-Systemhaus in Südwestfalen nun auch in MESCHEDE!",
            subheading: "Balve - Neheim - Plettenberg - Meschede",
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-homeoffice.jpg"),
            heading: "Bleiben Sie Gesund",
            subheading: "Lösungen für Homeoffice und Videokonferenzen",
            button: null,
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-managed-service.jpg"),
            heading: "Managed Services",
            subheading: null,
            button: {
                content: "mehr erfahren",
                clicked: () => this.showCompanyServiceModal("service"),
            },
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-server-network-cloud.jpg"),
            heading: "Server / Netzwerk / Cloud",
            subheading:
                "Steigern Sie Ihre Produktivität durch performante Hardware und leistungsstarke Anwendungen",
            button: {
                content: "mehr erfahren",
                clicked: () => this.showCompanyServiceModal("hardware"),
            },
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-software-development.jpg"),
            heading: "Softwareentwicklung",
            subheading:
                "Individuell nach Ihren Wünschen angepasste Standalone Anwendungen, Erweiterungen, Schnittstellen, u.v.m",
            button: {
                content: "mehr erfahren",
                clicked: () => this.showCompanyServiceModal("software"),
            },
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-dms-erp.jpg"),
            heading: "Planung ist alles!",
            subheading:
                "ERP-Systeme von LEXWARE ermöglichen Ihnen eine strukturierte und effizientere Verwaltung!",
            button: {
                content: "mehr erfahren",
                clicked: () => this.showCompanyServiceModal("software"),
            },
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-telephone-system.jpg"),
            heading: "High Performance Lösungen für Ihren Erfolg",
            subheading:
                "Telefonanlagen von 3CX ermöglichen Ihnen Telefonanrufe direkt am Computer zu tätigen. Ihr TK-System kann einfach geändert und erweitert werden.",
            button: {
                content: "mehr erfahren",
                clicked: () => this.showCompanyServiceModal("hardware"),
            },
        },
        {
            imageSrc: require("@/assets/img/carousel/slide-marketing.jpg"),
            heading: "Digitales Marketing",
            subheading:
                "Digitales Marketing - Alles rund um eine erfolgreiche Online Präsenz",
            button: {
                content: "mehr erfahren",
                clicked: () =>
                    this.showCompanyServiceModal("digitales marketing"),
            },
        },
    ];

    private companyPartners: CompanyPartner[] = [
        {
            name: "3CX",
            imageSrc: require("@/assets/img/partners/3cx.jpg"),
            link: "https://www.3cx.de/",
        },
        {
            name: "Barco",
            imageSrc: require("@/assets/img/partners/barco.jpg"),
            link: "https://www.barco.com/de",
        },
        {
            name: "HPE",
            imageSrc: require("@/assets/img/partners/hpe.png"),
            link: "https://www.hpe.com/de/de/home.html",
        },
        {
            name: "HP",
            imageSrc: require("@/assets/img/partners/hp.png"),
            link: "https://www.hp.com/de",
        },
        {
            name: "Lexware Gold",
            imageSrc: require("@/assets/img/partners/Lexware_gold.png"),
            link: "https://shop.lexware.de/",
        },
        {
            name: "Microsoft Silver",
            imageSrc: require("@/assets/img/partners/microsoft-silver.png"),
            link: "https://www.microsoft.com/de-de",
        },
        {
            name: "ontrack",
            imageSrc: require("@/assets/img/partners/ontrack-premium.jpg"),
            link: "https://www.ontrack.com/de-de",
        },
        {
            name: "Sophos Gold",
            imageSrc: require("@/assets/img/partners/sophos-gold.png"),
            link: "https://www.sophos.com/de-de",
        },
        {
            name: "iQuadrat",
            imageSrc: require("@/assets/img/partners/iquadrat.png"),
            link: "https://www.iquadrat.de/",
        },
        {
            name: "Flux",
            imageSrc: require("@/assets/img/partners/flux.png"),
            link: "https://www.fluxflux.de/",
        },
        {
            name: "Terra Partner",
            imageSrc: require("@/assets/img/partners/terra_partner.png"),
            link: "https://www.wortmann.de",
        },
        {
            name: "Terra Cloud",
            imageSrc: require("@/assets/img/partners/terra-cloud.png"),
            link: "https://www.wortmann.de/de-at/content/cloud-home.aspx",
        },
        {
            name: "Veeam Pro",
            imageSrc: require("@/assets/img/partners/veeam-pro.png"),
            link: "https://www.veeam.com/de",
        },
        {
            name: "Wecotel",
            imageSrc: require("@/assets/img/partners/wecotel.png"),
            link: "https://wecotel.de/",
        },
        {
            name: "Zoom",
            imageSrc: require("@/assets/img/partners/zoom.png"),
            link: "https://explore.zoom.us/de/products/meetings/",
        },
    ];
    // TODO  Größen anpassen, Wirtschaftsinitiaitve falsch
    private companyRecommendations: CompanyRecommendation[] = [
        {
            name: "Balver Mammuts",
            imageSrc: require("@/assets/img/recommendations/balver_mammuts_verbessert.png"),
            link: "https://www.segwaypolobalve.de/",
        },
        {
            name: "Balver Optimum",
            imageSrc: require("@/assets/img/recommendations/balver_optimum.jpg"),
            link: "https://balve-optimum.de/",
        },
        {
            name: "BNI Ruhrschleife Sauerland",
            imageSrc: require("@/assets/img/recommendations/BNI_Arnsberg.png"),
            link: "https://bni-nrwmitte.de/chapter-ruhrschleife-arnsberg/de/index",
        },
        {
            name: "BNI Hennesee",
            imageSrc: require("@/assets/img/recommendations/BNI_Hennesee.png"),
            link: "https://bni-rheinruhr.de/hennesee/de/index",
        },
        {
            name: "Lions Sorpesee",
            imageSrc: require("@/assets/img/recommendations/Lions_Logo_ohneHintergrund.png"),
            link: "https://lions-sorpesee.de/",
        },
        {
            name: "Städtische Realschule Balve",
            imageSrc: require("@/assets/img/recommendations/logo_srb_neu.svg"),
            link: "https://www.realschulebalve.de/",
        },
        {
            name: "Wirtschaftsinitiative Iserlohn",
            imageSrc: require("@/assets/img/recommendations/WIS.jpg"),
            link: "https://www.info-wis.de/home",
        },
        {
            name: "Südwestfalen",
            imageSrc: require("@/assets/img/recommendations/SWF_Standort_RGB_transparent.png"),
            link: "https://www.suedwestfalen-agentur.com/",
        },
        {
            name: "TimeMoto",
            imageSrc: require("@/assets/img/recommendations/TimeMoto_Logo.webp"),
            link: "https://www.timemoto.com",
        },
    ];

    private companyServiceModal: CompanyServiceModal = {
        show: false,
        header: "",
        expansionPanelItems: [],
    };

    private companyFoundingYear = 1988;

    private companyEmployeeCount = 20;

    private companyAboutUs = [
        "Wir sind ein Team bestehend aus {employeeCount} Mitarbeitern.",
        "Unsere Kompetenzen erstrecken sich über den gesamten IT-Bereich von der einfachen Computermaus, über IT-Sicherheit, bis hin zur fertigen Serverinfrastruktur.",
        "Auf Kundenwunsch erstellen & designen wir die passende Website oder Individualsoftware.",
        "Sie finden uns an vier Standorten im Sauerland und Märkischen-Kreis:",
    ];

    private companyLocations: CompanyLocation[] = [
        {
            location: "Balve",
            isHeadquarters: true,
        },
        {
            location: "Meschede",
            isHeadquarters: false,
        },
        {
            location: "Neheim",
            isHeadquarters: false,
        },
        {
            location: "Plettenberg",
            isHeadquarters: false,
        },
    ];

    private companySkills: CompanySkill[] = [
        {
            title: "Hardware",
            text: "Wir bieten maßgeschneiderte Hardwarelösungen für Ihr Unternehmen. Von Serverlösungen über Netzwerkkomponenten bis zur Telefonanlage - wir statten Sie aus von A bis Z.",
            imageSrc: require("@/assets/img/skills/skill-hardware.jpg"),
        },
        {
            title: "Software",
            text: "Egal ob Sie individuelle Software, eine Website mit SEO & SEA Optimierung oder Ihre Buchhaltung digitalisieren wollen - bei uns sind Sie an der richtigen Adresse.",
            imageSrc: require("@/assets/img/skills/skill-software.jpg"),
        },
        {
            title: "Service",
            text: "Bei uns werden Sie mit ihren Wünschen nicht allein gelassen. Vom Consulting über die Durchführung des Projekts bis zur stetigen Wartung mit unseren Managed Services: wir stehen an Ihrer Seite.",
            imageSrc: require("@/assets/img/skills/skill-service.jpg"),
        },
        {
            title: "Digitales Marketing",
            text: "Sie sind mit Ihrer Firma noch nicht Präsent auf den sozialen Netzwerken oder schaffen es Zeitlich nicht Ihre Kanäle zu verwalten? Kein Problem! Sie kümmern sich um Ihr Geschäft & Wir machen Sie online sichtbar bei Ihren potenziellen Kunden.",
            imageSrc: require("@/assets/img/skills/skill-marketing.png"),
        },
    ];

    private companyContactPersons: CompanyContactPerson[] = [
        {
            name: "Martin Skiba",
            post: "Geschäftsführer",
            imageSrc:
                "https://skiba-procomputer.de/assets/images/team/full/ms.jpg",
        },
        {
            name: "Kathrin Skiba",
            post: "Kundenservice",
            imageSrc:
                "https://skiba-procomputer.de/assets/images/team/full/ms.jpg",
        },
        {
            name: "Matthias Obst",
            post: "Technik",
            imageSrc:
                "https://skiba-procomputer.de/assets/images/team/full/ms.jpg",
        },
    ];

    private companyContactInformations: CompanyContactInformation[] = [
        {
            title: "Adresse Balve (Zentrale)",
            text: "Hönnetalstraße 54 a-b, 58802 Balve",
            icon: "mdi-map-marker",
        },
        {
            title: "E-Mail Balve (Zentrale)",
            text: "info@skiba-procomputer.de",
            icon: "mdi-email",
        },
        {
            title: "Telefonnummer Balve (Zentrale)",
            text: "+49 (0) 2375 203 903",
            icon: "mdi-phone",
        },
        {
            title: "skiba IT-Systemhaus Meschede",
            text: "Im Schlahbruch 6, 59872 Meschede",
            icon: "mdi-map-marker",
        },
        {
            title: "E-Mail Meschede",
            text: "meschede@skiba-procomputer.de",
            icon: "mdi-email",
        },
        {
            title: "Telefonnummer Meschede",
            text: "+49 (0) 291 902 494 84",
            icon: "mdi-phone",
        },
        {
            title: "Adresse Plettenberg (Servicepoint)",
            text: "58840 Plettenberg",
            icon: "mdi-map-marker",
        },

        {
            title: "E-Mail Plettenberg (Servicepoint)",
            text: "plettenberg@skiba-procomputer.de",
            icon: "mdi-email",
        },
        {
            title: "Telefonnummer Plettenberg (Servicepoint)",
            text: "+49 (0) 2391 601 653 0",
            icon: "mdi-phone",
        },
        {
            title: "Adresse Arnsberg-Neheim (Servicepoint)",
            text: "59755 Arnsberg-Neheim",
            icon: "mdi-map-marker",
        },

        {
            title: "E-Mail Arnsberg-Neheim (Servicepoint)",
            text: "arnsberg-neheim@skiba-procomputer.de",
            icon: "mdi-email",
        },
        {
            title: "Telefonnummer Arnsberg-Neheim (Servicepoint)",
            text: "+49 (0) 2932 494 900 9 ",
            icon: "mdi-phone",
        },
    ];

    get parsedCompanyAboutUs(): string[] {
        return this.companyAboutUs.map((paragraph) =>
            paragraph.replace(
                "{employeeCount}",
                this.companyEmployeeCount.toString()
            )
        );
    }

    private showCompanyServiceModal(service: string) {
        const expansionPanelItems: ExpansionPanelItem[] = [];
        const modalHeader =
            service[0].toUpperCase() + service.slice(1).toLowerCase();

        switch (service.toLowerCase()) {
            case "hardware":
                expansionPanelItems.push({
                    header: "Serverlösungen",
                    content: [
                        {
                            header: "Windows Server",
                            text: "Sind Sie es leid, jede Freigabe von Hand einzurichten? Ärgern Sie sich regelmäßig damit herum, dass der Kollege aus der Abteilung gegenüber auf Ihrem Drucker druckt?  Mit Windows-Server und Active-Directory können Sie diese Probleme aus dem Weg räumen – zentral und automatisiert.  Ob Dateiserver, Webserver oder Terminalserver - On-Premise, Hybrid oder komplett in der Cloud mit Microsoft Azure? Kein Problem! Als langjähriger Microsoft Partner bieten wir Ihnen genau auf Sie zugeschnittene Windows-Server-Lösungen mit der passenden Hardware von Terra und HPE an.",
                        },
                        {
                            header: "Linux Server",
                            text: "Schlank, flexibel, open-source – mit unserer langjährigen Linux-Erfahrungen bieten wir eine breite Auswahl von Linux-Serverlösungen. Vom Web- über Datenbank- und Druckerserver – es gibt viele Alternativen, die wir gerne für Sie realisieren.  Von Planung und Einrichtung der Hardware bis hin zur Administration – bei uns sind Sie an der richtigen Adresse.",
                        },
                        {
                            header: "Mail-Server",
                            text: "Sie möchten Ihre Mails nicht in die Cloud auslagern, sondern selbst hosten? Wir bieten Mailserver als AllInOne Lösung auf Basis von Windows Exchange 2019 mit der richtigen Hardware, vorkonfiguriert im rundum-sorglos-Paket an. Gerne kümmern wir uns auch um die passende E-Maildomain.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Netzwerkkomponenten",
                    content: [
                        {
                            header: "Netzwerkkomponenten",
                            text: "Die Netzwerkgeschwindigkeit ist für Intranet-Anwendungen von besonders großer Bedeutung. Wir sorgen für die richtige Anbindung zwischen Ihren Clients und Servern auf Basis des großen Angebots unserer Partner. Vom Homeoffice bis zum 10000m² Lager, Kabel oder Wireless – alles ist möglich.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Clients",
                    content: [
                        {
                            header: "Clients",
                            text: "Wir helfen Ihnen gerne bei der Auswahl der richtigen Büro-PCs oder Workstations. Neben unseren Partnern Terra und HP Business gehören auch Linux-basierte Systeme und die breite Produktpalette von Apple zu unserem Portfolio.  Sie benötigen eine Workstation mit besonderen Spezifikationen?  Mit Terra Build-To-Order Lösungen liefern wir Ihnen Qualität Made in Germany.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Telefonanlagen 3CX",
                    content: [
                        {
                            header: "Telefonanlagen 3CX",
                            text: "Telefonie der extra-Klasse, aus der Cloud oder On-Premise. Vom Computer, Tischtelefon oder unterwegs vom Smartphone: 3CX ermöglicht Ihnen fortschrittlichste Funktionen überall zu jeder Zeit.  Mit den erweiterten Warteschleifen Features und der Facebook – SMS- und Livechatintegration haben Sie die Werkzeuge in der Hand, die Sie für erfolgreiche Kommunikation benötigen. Wir bieten Lösungen für jede Unternehmensgröße mit unserer skibaphone-Appliance.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Firewalls",
                    content: [
                        {
                            header: "Firewalls",
                            text: "Würden Sie die Sicherheit Ihres Eigenheims einer Tür aus Pappe anvertrauen? Warum dann nur auf die Windows-eigene Firewall setzen? Mit Sophos XG Firewalls werden kompromittierte Systeme automatisch isoliert, bis diese bereinigt sind – dank des Secruity Heartbeats. Network-, Web-, und Mail-Protection: in einem Paket, zentral verwaltbar. Sie möchten aus dem Home-Office arbeiten? Sophos bietet mit dem integrierten VPN-Dienst einen sicheren Zugang zu Ihrem Firmennetz, vom PC, Notebook, Tablet oder Smartphone.  Sophos bietet dabei Lösungen für jede Unternehmensgröße.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Personal Zeiterfassung TimeMoto",
                    content: [
                        {
                            header: "Personal Zeiterfassung TimeMoto",
                            text: "Wissen Sie genau, wann Ihre Mitarbeiter kommen und gehen – oder verlassen Sie sich noch auf handgeschriebene Stundenzettel? Mit TimeMoto haben Sie die Arbeitszeiten immer im Blick, automatisiert und fälschungssicher. Ob per RFID, Fingerabdruck oder PIN – die Erfassung ist schnell, sicher und nahtlos in Ihre Lohnabrechnung integrierbar. Dank Cloud-Anbindung behalten Sie auch im Home-Office oder unterwegs den Überblick. So sparen Sie Zeit, vermeiden Fehler und optimieren Ihre Personalplanung. TimeMoto bietet Lösungen für Unternehmen jeder Größe –  einfach, flexibel und zuverlässig.",
                        },
                    ],
                });
                break;

            case "software":
                expansionPanelItems.push({
                    header: "Individualsoftware",
                    content: [
                        {
                            header: "Webanwendung",
                            text: "Unser erfahrenes Team von Software-Entwicklern bietet Ihnen zeitgemäße und plattformunabhängige Webanwendungen nach Ihren Wünschen an. Zentrale Datenhaltung, responsive Design und die freie Skalierbarkeit sind nur einige, der vielen Vorteile, die Sie mit perfekt auf Sie zugeschnittenen Webanwendungen genießen könnten.",
                        },
                        {
                            header: "Betriebssystemnahe Anwendungen",
                            text: "Wir bieten auf Sie zugeschnittene iOS, Android und Windows Anwendungen, um Ihren professionellen Auftritt zu gewährleisten. Dank der lokalen Datenhaltung sind unsere Anwendungen selbstverständlich auch vollkommen offline verwendbar.",
                        },
                        {
                            header: "Schnittstellenprogrammierung",
                            text: "Egal, wo Sie eine Verbindung zwischen mehreren Systemen benötigen: unser erfahrenes Team ermöglichen den Transfer von Daten zwischen unterschiedlichen vorhandenen Applikationen.",
                        },
                        {
                            header: "Datenbank Design",
                            text: "Ärgern Sie sich noch mit riesigen Excel-Listen und Duplikaten herum? Wir erstellen die passende Datenbankstruktur unter Berücksichtigung aller aktuellen Sicherheitsanforderung.  Ganz frei von Duplikaten und für eine einfache und übersichtliche Handhabung optimiert.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Websiteerstellung",
                    content: [
                        {
                            header: "Websiteerstellung",
                            text: "Fehlt Ihnen noch Ihr Internetauftritt oder benötigt Ihre Website eine Verjüngungskur? Wir erstellen den professionellen Webauftritt für Ihr Unternehmen – plattformunabhängig und mit responsive Design.  Auch Webshops zählen zu unseren Kernkompetenzen.  Damit Sie Ihre Zielgruppe perfekt ansprechen können, sind all unsere Webseiten selbstverständlich SEO optimiert!",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "SEO & SEA",
                    content: [
                        {
                            header: "SEO & SEA",
                            text: "Wussten Sie, dass Google 63.000-mal pro Sekunde aufgerufen wird?  Mit Suchmaschinenoptimierung und der passenden Werbung ermöglichen wir die Sichtbarkeit, die Ihr Unternehmen verdient.  Ermöglichen Sie Ihren zukünftigen Kunden, Sie zu finden.  Unser erfahrenes Team berät Sie gerne.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Lexware",
                    content: [
                        {
                            header: "Lohn+Gehalt",
                            text: "Sparen Sie sich das aufwändige Ausfüllen von Formularen. Bescheinigungen für Ämter, Behörden und Mitarbeiter werden automatisch ausgefüllt.  Sparen Sie sich Überstunden im Büro. Erledigen Sie die Lohnbuchhaltung auch zuhause oder unterwegs – mit einer zweiten Lizenz für Ihren Laptop.  Sparen Sie viel Zeit bei der Abrechnung von Kurzarbeit. Das übernimmt das Programm für Sie.",
                        },
                        {
                            header: "Buchhaltung",
                            text: "Sparen Sie sich doppelte Arbeit bei der Kassenführung. Führen Sie Ihre gesamten Kassen direkt am PC – mit der integrierten Kassenbuchanbindung.  Sparen Sie sich das manuelle Ausfüllen der Umsatzsteuerjahreserklärung. Diese wird vom Programm automatisch ausgefüllt.  Sparen Sie sich Überstunden im Büro. Erledigen Sie Ihre Büroarbeit auch zuhause oder unterwegs – mit einer zweiten Lizenz für Ihren Laptop.  Sparen Sie viel Zeit bei der Kontenzuordnung. Ordnen Sie mit dem DATEV-Kontenzuordnungsmodul ruckzuck die Konten, die nicht den üblichen DATEV-Nummernkreisen entsprechen oder die Sie zusammengefasst haben, den passenden DATEV-Konten zu.",
                        },
                        {
                            header: "Faktura+Auftrag",
                            text: "Sparen Sie sich das manuelle Eintragen Ihrer Lieferanten-Kataloge ins Programm. Dank der DATANORM-Schnittstelle werden die Artikeldaten automatisch integriert.  Sparen Sie sich Überstunden im Büro. Schreiben Sie Ihre Angebote und Rechnungen auch zuhause oder unterwegs – mit einer zweiten Lizenz für Ihren Laptop.  Sparen Sie viel Zeit bei der Umrechnung von Mengenangaben zur Preisermittlung. Das übernimmt die integrierte Lexware formelverwaltung für Sie.",
                        },
                        {
                            header: "Financial Office",
                            text: "Sparen Sie sich das manuelle Eintragen Ihrer Lieferanten-Kataloge ins Programm. Dank der DATANORM-Schnittstelle werden die Artikeldaten automatisch integriert.  Sparen Sie sich Überstunden im Büro. Erledigen Sie Ihre Büroarbeit auch zuhause oder unterwegs – mit einer zweiten Lizenz für Ihren Laptop.  Sparen Sie sich doppelte Arbeit bei der Kassenführung. Führen Sie Ihre gesamten Kassen direkt am PC – mit der integrierten Kassenbuchanbindung.  Sparen Sie sich das manuelle Ausfüllen der Umsatzsteuerjahreserklärung. Diese wird vom Programm automatisch ausgefüllt.  Sparen Sie viel Zeit bei der Umrechnung von Mengenangaben zur Preisermittlung. Das übernimmt die integrierte Lexware formelsammlung für Sie.",
                        },
                        {
                            header: "Lexoffice",
                            text: "Die Online Unternehmersoftware von Lexware macht Buchhaltung automatisch und bietet ein genial digitales Office. Damit sind Angebote erstellen, Rechnungen schreiben, Kunden verwalten, Lohnabrechnung erledigen oder die Steuererklärung vorbereiten kein Problem mehr. Hohe Zeitersparnis bei Verwaltungsaufgaben (z.B. Monatsrechnungen, Lohnabrechnung) Mobiles Arbeiten per App, Smartphone, Tablet oder Notebook (auch Apple Mac) Einfache Bedienung ohne Buchhaltungskenntnisse Finanzen im Blick in einem Programm (Steuern + Rechnungen + Online-Banking + Buchhaltung) GoBD testiert und Finanzamt-anerkannt DSGVO Datenschutz-konform und rechtssicheres Arbeiten Vom Marktführer „Lexware“ mit jahrzehntelanger Erfahrung",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Microsoft 365",
                    content: [
                        {
                            header: "Microsoft 365",
                            text: "Neben den klassischen Office-Produkten ermöglicht Microsoft Teams mit Microsoft Sharepoint einen optimalen Workflow dank Videotelefonie und geteilten Inhalten über ein intelligentes Intranet. Darüber hinaus erwartet Sie eine Vielzahl von Anwendungen, wie OneDrive als Cloudspeicher, Planner für das professionelle Arbeitsmanagement und MyAnalytics für die Analyse Ihres Workflows.  Microsoft 365 ist frei skalierbar und für jede Unternehmensgröße, vom Kleinstunternehmen bis zum Big Player, perfekt zugeschnitten.  Mit Microsoft Exchange Online brauchen Sie sich keine Gedanken mehr um die Ressourcen und die Sicherheit Ihrer Mails zu machen – denn Ihr Mailserver liegt in den performanten und hochsicheren Rechenzentren von Microsoft.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Sophos Endpoint",
                    content: [
                        {
                            header: "Sophos Endpoint",
                            text: "Schützen Sie all Ihre Geräte vor Bedrohungen durch Angreifer und Schadsoftware mit der besten Endpoint Protection am Markt. Die automatische Erkennung und Priorisierung potenzieller Bedrohungen, der integrierter Anti-Ransomware Schutz und die Exploit Prevention sind nur einige der vielen Features, die durch die KI-gestützte Deep-Learning-Technologie mit jedem Tag besser werden. Dies alles wird zentral und übersichtlich verwaltet. Es ist schon zum Schadensfall gekommen? Mit Sophos MTR ergreift das Expertenteam unseres Partners gezielt Maßnahmen, um auch hochkomplexe Bedrohung unschädlich zu machen. Sophos Endpoint ist die perfekte Ergänzung zu Ihrer Sophos Firewall.  Durch den synchronized Heartbeat werden so infizierte oder gefährdete Endgeräte vom Rest des Netzwerks isoliert – automatisch und augenblicklich.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Zoom",
                    content: [
                        {
                            header: "Zoom",
                            text: "Unser Partner Zoom ermöglicht Ihrem Unternehmen professionelle Webkonferenzen mit Instant-Messaging und Content-Sharing – und das Ganze mit einer einzigartigen Benutzerfreundlichkeit.  Zoom ist dabei voll skalierbar. Vom 4 Augen Gespräch über das Team-Meeting oder Online-Veranstaltungen ist alles möglich.  Dabei brauchen Sie sich keine Sorgen, um die Sicherheit zu machen, denn die zuverlässigen Sicherheitseinstellungen inkl. Verschlüsselung, rollenbasierter Sicherheit und Passwortschutz sorgen für störungsfreie Meetings.",
                        },
                    ],
                });
                break;
            case "service":
                expansionPanelItems.push({
                    header: "Managed Services",
                    content: [
                        {
                            header: "Managed Services",
                            text: "Systemausfälle vorhersehen, bevor sie passieren – ganz ohne Kristallkugel. Mit unseren skiba Managed Services überwachen wir die Gesundheit Ihrer Systeme und können eingreifen, bevor es zu Störungen in Ihrer EDV kommt. Der kritische S.M.A.R.T-Status Ihrer Festplatten, Windows-Systemmeldungen und das Fehlen wichtiger Sicherheitsupdates sind nur einige der Punkte, die wir für Sie im Blick haben. Gönnen Sie sich die Erleichterung zu wissen, dass Ihrer Produktivität nichts im Wege steht.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Backup Monitoring",
                    content: [
                        {
                            header: "Backup Monitoring",
                            text: "Gab es am Wochenende ein Rohrbruch über dem Serverraum oder hat der neue Praktikant aus Versehen Kaffee über Ihren Laptop geschüttet? Damit Sie auch auf das Unvorhersehbare vorbereitet sein können, bieten wir Ihnen individualisierte Backup-Lösungen für Ihr Unternehmen. Damit Sie Ihre wertvolle Zeit nicht mit der stetigen Kontrolle der Backups verbringen müssen, kümmert sich unser geschultes Team im passiven oder aktiven Backup-Monitoring darum, dass Ihre wichtigen Systeme und Daten zuverlässig gesichert werden.  Selbstverständlich stehen wir Ihnen darüber hinaus auch vom Backup-Konzept bis zur Desaster-Recovery zur Verfügung.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "IT-Sicherheitsanalyse",
                    content: [
                        {
                            header: "IT-Sicherheitsanalyse",
                            text: "Über 100.000 Cyberangriffe im letzten Jahr – allein in Deutschland.  Und die Zahl steigt stetig. Damit Ihr Unternehmen diese Zahl nicht noch nach oben treibt bieten wir ausführliche IT-Sicherheitsanalysen: vom Web bis zur Sicherheit Ihrer Büros. Wir finden Ihre Sicherheitslücken, zeigen Ihnen mögliche Gegenmaßnahmen auf - und setzen diese bei Bedarf auch gerne für Sie um.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "DSGVO",
                    content: [
                        {
                            header: "DSGVO",
                            text: "Man verliert schnell den Überblick, bei den vielen Details, die die DSGVO fordert. Wir nehmen Sie an die Hand und führen Sie durch diesen Paragraphendschungel. Gerne prüfen wir Ihr Unternehmen und Ihren Webauftritt auf DSGVO-Konformität und führen die benötigten Anpassungen durch. Selbstverständlich ist dieser Service auch bei all unseren Webseiten inklusive! Gerne übernehmen wir auch die Funktion des Datenschutzbeauftragten.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Consulting",
                    content: [
                        {
                            header: "Consulting",
                            text: "Ihr Unternehmen boomt – aber Ihre EDV bremst sie gerade aus und sie wissen nicht, wo Sie anfangen sollen? Kein Problem. Wir bieten professionelles Consulting, auch für das größte Projekt. Dabei stehen wir Ihnen kompetent zur Seite - von der Beratung und dem Angebot bis zur Durchführung und Wartung.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Fernwartung",
                    content: [
                        {
                            header: "Fernwartung",
                            text: "Immer da, an jedem Ort. Dank unserer Fernwartungslösungen können wir Ihnen überall zur Seite stehen – Sie brauchen nur Zugriff zum Internet. Ganz egal, ob Sie eine Funktion im Microsoft Office suchen oder Ihre CAD-Anwendung plötzlich nicht mehr startet – mit der skiba Fernwartung sind wir nur einen Klick von der Lösung entfernt.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Gerätereparatur",
                    content: [
                        {
                            header: "Gerätereparatur",
                            text: "Verschönert Ihr Drucker Ihre Ausdrucke mit schönen bunten Streifen? Gibt Ihr Computer bei dem Versuch, ihn zu starten, nur noch ein Techno-Konzert von sich?  Unsere kompetenten Techniker reparieren Ihre Geräte und Sorgen dafür, dass diese wieder zuverlässig funktionieren. Dabei stehen uns unsere Partner mit einer Vielzahl von Ersatzteilen zur Seite.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Hardwareverleih",
                    content: [
                        {
                            header: "Hardwareverleih",
                            text: "Sie haben ein großes Event geplant – aber Ihnen fehlt noch der passende Drucker? Wir verleihen die benötigten Geräte, stellen diese vor Ort auf und holen Sie am Ende des Events wieder ab.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Softwareinstallation / Umzug",
                    content: [
                        {
                            header: "Softwareinstallation / Umzug",
                            text: "Sie haben einen neuen PC bei uns erworben, möchten aber Ihre Programme und Daten übernehmen? Unsere erfahrenen Techniker migrieren Ihre Software und Daten auf das neue System. Dabei prüfen wir selbstverständlich auf Kompatibilität und Performance und Sorgen im Falle des Falles für passende Alternativen.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Virenbereinigung",
                    content: [
                        {
                            header: "Virenbereinigung",
                            text: "Der falsche E-Mailanhang oder Website-Link und schon ist es passiert: Ihr PC ist von einem Virus befallen. Kein Grund zur Sorge: unser Team bereinigt Ihre Endgeräte und führt im Worst-Case Szenario eine Neuinstallation inkl. Datenrettung durch.",
                        },
                    ],
                });
                break;
            case "digitales marketing":
                expansionPanelItems.push({
                    header: "Social Media",
                    content: [
                        {
                            header: "Social Media",
                            text: "Sie sind mit Ihrer Firma noch nicht Präsent auf den sozialen Netzwerken oder schaffen es Zeitlich nicht Ihre Kanäle zu verwalten? Kein Problem! Sie kümmern sich um Ihr Geschäft & Wir machen Sie online sichtbar bei Ihren potenziellen Kunden.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "SEA & SEO",
                    content: [
                        {
                            header: "SEA & SEO",
                            text: "Wir optimieren Ihr Suchmaschinenmarketing und schaffen Ihnen mehr Sichtbarkeit innerhalb der Ergebnislisten. Egal ob bei Google oder in den Sozialen Netzwerken. Gewinnen Sie jetzt mehr Besucher mit Ihrer digitalen Präsenz.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Webdesign & Homepages",
                    content: [
                        {
                            header: "Webdesign & Homepages",
                            text: "Wir designen Ihnen einen Webauftritt der optimal zu Ihrer Corporate Identity passt. Dabei achten wir auf Innovation & Individualität.",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Grafikdesign",
                    content: [
                        {
                            header: "Grafikdesign",
                            text: "Wir übernehmen die Gestaltung all Ihrer Medien. Von Printmedien über die Erstellung Ihrer online Inhalte bis hin zu Ihrer eigenen Werbeanzeige.",
                        },
                    ],
                });
                break;
            case "fachinformatiker/in systemintegration (vollzeit)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text: "Erfolgreich abgeschlossenes Informatik Studium oder vergleichbare Ausbildung - Min. 2-3 Jahre Berufserfahrung - Praktische Kenntnisse in der Administration von Windows-Server - Teamfähigkeit und analytische Denkweise - Gute Deutsch- und Englischkenntnisse in Schrift und Wort",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text: "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Leistungsgerechte Vergütung - Projektprämien - Sonderbezahlung von Überstunden",
                        },
                    ],
                });
                break;
            case "fachinformatiker/in anwendungsentwicklung (vollzeit)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text: "Erfolgreich abgeschlossenes Informatik Studium oder vergleichbare Ausbildung - Min. 2-3 Jahre Berufserfahrung - Praktische Kenntnisse in der Administration von Windows-Server - Teamfähigkeit und analytische Denkweise - Gute Deutsch- und Englischkenntnisse in Schrift und Wort",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text: "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Leistungsgerechte Vergütung - Projektprämien - Sonderbezahlung von Überstunden",
                        },
                    ],
                });
                break;
            case "it-systemelektroniker/in (vollzeit)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text: "Erfolgreich abgeschlossenes Informatik Studium oder vergleichbare Ausbildung - Min. 2-3 Jahre Berufserfahrung - Praktische Kenntnisse in der Administration von Windows-Server - Teamfähigkeit und analytische Denkweise - Gute Deutsch- und Englischkenntnisse in Schrift und Wort",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text: "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Leistungsgerechte Vergütung - Projektprämien - Sonderbezahlung von Überstunden",
                        },
                    ],
                });
                break;
            case "it-systemkaufmann/frau (vollzeit)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text: "Erfolgreich abgeschlossenes Informatik Studium oder vergleichbare Ausbildung - Min. 2-3 Jahre Berufserfahrung - Praktische Kenntnisse in der Administration von Windows-Server - Teamfähigkeit und analytische Denkweise - Gute Deutsch- und Englischkenntnisse in Schrift und Wort",
                        },
                    ],
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text: "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Leistungsgerechte Vergütung - Projektprämien - Sonderbezahlung von Überstunden",
                        },
                    ],
                });
                break;
        }

        this.companyServiceModal.header = modalHeader;
        this.companyServiceModal.expansionPanelItems = expansionPanelItems;
        this.companyServiceModal.show = true;
    }
}
